<template>
  <div>
    <div class="borderColor mb-3">
      <div
        class="text-center d-flex flex-column font-weight-bold titleBackground"
      >
        <div class="highcharts-title p-3">
          {{ $t("deviceStatus.deviceLiveInformation") }}
        </div>
      </div>
      <Highcharts :options="deviceStatusChartOptions" />
    </div>

    <div class="borderColor">
      <div class="text-center d-flex flex-column titleBackground">
        <div class="highcharts-title mt-4">
          {{ $t("deviceStatus.invLiveInformation") }}
        </div>
        <div class="highcharts-title">{{ dataTime }}</div>

        <div class="d-flex align-items-center">
          顯示筆數:
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="ml-2 mr-3"
            style="width: 80px"
          />
          <b-form-checkbox v-model="isOnlyPV" class="text-dark" switch>
            僅顯示 PV
          </b-form-checkbox>
          <button
            class="confirmButton ml-auto"
            style="width: 120px"
            @click="downloadAnalysis"
          >
            {{ $t("dataAnalysis.download") }}
          </button>
        </div>
      </div>
      <b-table
        :items="tableData"
        :fields="tableField"
        hover
        class="tableStyle bTableSticky"
        :head-variant="tableThColor"
        :dark="dark"
        :responsive="true"
        :sort-by="sortBy"
        sort-icon-left
        :per-page="perPage"
        :current-page="currentPage"
        :tbody-transition-props="transProps"
        primary-key="INV 名稱"
        sticky-header
      >
        <template #cell(連線狀態)="data">
          <i
            v-if="data.value === 0"
            :class="'fas fa-check-circle'"
            style="color: var(--success)"
          />
          <i
            v-else
            :class="'fas fa-times-circle'"
            style="color: var(--purple)"
          />
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      class="mt-2 mb-4"
    />
  </div>
</template>
<script>
import { BTable, BPagination } from 'bootstrap-vue'
import downloadCsv from 'download-csv'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DeviceStatus',
  components: {
    BTable,
    BPagination
  },
  data () {
    return {
      sortBy: 'INV 名稱',
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      isOnlyPV: false
    }
  },
  computed: {
    ...mapState('deviceStatus', [
      'deviceStatusChartOptions',
      'tableField',
      'tableData',
      'dataTime'
    ]),
    ...mapState(['dark', 'tableThColor', 'factoryData']),
    rows () {
      return this.tableData.length
    }
  },
  methods: {
    ...mapActions(['asyncLastSingleDoc', 'updateDevicesStatusState']),
    ...mapActions('deviceStatus', ['handleTableField']),
    downloadAnalysis () {
      // remove _cellVariants
      const downloadData = this.tableData.map((obj) => {
        const { _cellVariants, ...newObject } = obj
        return newObject
      })
      downloadCsv(downloadData, {}, '變流器詳細資訊.csv')
    }
  },
  watch: {
    isOnlyPV: function (newValue, _oldValue) {
      if (newValue) {
        this.handleTableField(true)
      } else {
        this.handleTableField()
      }
    }
  },
  async created () {
    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.$route.query.siteName)
    }
    this.updateDevicesStatusState()
    this.handleTableField()
  }
}
</script>
